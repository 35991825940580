<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-row class="align-center justify-center">
          <v-card flat color="#FBDE44FF">
            <v-card-title class="subtitle-2">
              네이버 로그인 중입니다.
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-card-title>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable no-console, no-unused-var, no-undef */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import { mapGetters } from 'vuex';

import api_conf from '../config/config.json';

export default {
  props: {
    msg: String
  },
  computed: {
    ...mapGetters({

      // time: "time",
    }),
  },
  mounted: async function() {
    let link = this.$route.hash.split('#')[1];
    let access_token = link.split('&')[0].split('=')[1];

    let customtoken = await axios.post(api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/naver/auth', {
      token: access_token,
    });

    const res = await firebase.auth().signInWithCustomToken(customtoken.data.token);

    const id_token = await firebase.auth().currentUser.getIdToken(true);
    await this.$store.dispatch("postIDToken", id_token);

    const is_exist = await axios.get(
      api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/user/is', {
      params: { email: customtoken.data.email },
      headers: {id_token: id_token},
    });

    await this.$store.dispatch("fetchUser", res.user);

    if(is_exist.data==0) {
      this.$router.push({ path: "/agreement" });
    } else {
      this.$router.push({ path:"/main" });
    }
  }

}
</script>

<style scoped>
</style>
